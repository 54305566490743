<template>
  <div class="invoice-header">
    <div class="content">
      <p class="title">开票确认</p>
      <div>
        <div class="form-div">
          <el-form label-width="110px" ref="ruleForm">
            <el-form-item label="发票类型">
              <el-radio-group v-model="form.invoiceType" @change="changeInvoiceType">
                <el-radio label="PLAIN_VAT_INVOICE">增值税普通发票</el-radio>
                <el-radio label="SPECIAL_VAT_INVOICE">增值税专用发票</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="抬头类型">
              <el-radio-group v-model="form.titleType" @change="changeTitleType">
                <el-radio label="ENTERPRISE">企业单位</el-radio>
                <el-radio label="PERSONAL" :disabled="form.invoiceType === 'SPECIAL_VAT_INVOICE'"
                  >个人/非企业单位</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="发票抬头">
              <el-select v-model="form.id" clearable placeholder="请选择" @change="handeChange">
                <el-option
                  v-for="item in listForOrder"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-button type="text" style="margin-left: 20px;" @click="addTitle">新增开票主体</el-button>
            </el-form-item>
            <template v-if="form.id">
              <el-form-item label="税号" v-if="form.titleType === 'ENTERPRISE'">
                <el-input :disabled="true" v-model="form.taxNo"></el-input>
              </el-form-item>
              <el-form-item label="开户银行" v-if="form.titleType === 'ENTERPRISE'">
                <el-input :disabled="true" v-model.trim="form.bankName"></el-input>
              </el-form-item>
              <el-form-item label="开户行账号" v-if="form.titleType === 'ENTERPRISE'">
                <el-input :disabled="true" v-model.trim="form.bankAccount"></el-input>
              </el-form-item>
              <el-form-item label="企业电话" v-if="form.titleType === 'ENTERPRISE'">
                <el-input :disabled="true" v-model.trim="form.mobile"></el-input>
              </el-form-item>
              <el-form-item label="企业地址" v-if="form.titleType === 'ENTERPRISE'">
                <el-input :disabled="true" v-model.trim="form.address"></el-input>
              </el-form-item>
              <el-form-item label="联系人" prop="contactName">
                <el-input :disabled="true" v-model.trim="form.contactName"></el-input>
              </el-form-item>
              <el-form-item label="联系人邮箱" prop="email">
                <el-input :disabled="true" v-model.trim="form.email"></el-input>
              </el-form-item>
              <el-form-item label="联系人手机号" prop="contactPhone">
                <el-input :disabled="true" v-model.trim="form.contactPhone"></el-input>
              </el-form-item>
              <el-form-item label="收件地址" prop="areaCodes">
                <AreaCascader :isDetail="true" class="area-width" v-model="form.areaCodes" />
              </el-form-item>
              <el-form-item label="详细地址" prop="addressDetail">
                <el-input :disabled="true" v-model.trim="form.addressDetail"></el-input>
              </el-form-item>
            </template>
            <el-form-item label="开票备注">
              <el-input type="textarea" v-model="form.description" maxlength="100" show-word-limit :rows="3"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="sure-div">
          <el-button @click="backPage" size="small">取 消</el-button>
          <el-button :loading="saveLoading" @click="sure" size="small" type="primary"
            >确定提交</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AreaCascader from '@/components/AreaCascader';
import { createNotHaveContract, getListForOrder } from '@/api/invoice';
export default {
  name: 'invoice-confirm-header',
  components: {
    AreaCascader,
  },
  data() {
    return {
      form: {
        id: '',
        invoiceType: 'SPECIAL_VAT_INVOICE',
        titleType: 'ENTERPRISE',
        title: '',
        taxNo: '',
        bankName: '',
        bankAccount: '',
        address: '',
        mobile: '',
        email: '',
        areaCodes: null,
        contactAddress: '',
        description: '',
      },
      saveLoading: false,
      invoiceTitleAllList: [],
      orderBrandType: '',
      resultId: '',
      orderIds: [],
      izAllInvoice: '0',
    };
  },
  computed: {
    listForOrder() {
      return this.invoiceTitleAllList.filter(item => item.titleType === this.form.titleType);
    },
  },
  created() {
    this.getMyDefault();
  },
  methods: {
    async getMyDefault() {
      this.orderBrandType = this.$route.query.orderBrandType;
      this.resultId = this.$route.query.resultId;
      this.orderIds = JSON.parse(sessionStorage.orderIds);
      this.izAllInvoice = sessionStorage.izAllInvoice;
      const { data: res } = await getListForOrder({
        orderBrandType: this.orderBrandType
      });
      if (res) {
        this.invoiceTitleAllList = res;
      }
    },
    sure() {
      const { izAllInvoice, resultId, orderBrandType, orderIds, form } = this;
      if(!form.id) {
        this.$message({
          message: '请先选择开票抬头',
          type: 'warning',
        });
        return;
      }
      this.saveLoading = true;
      createNotHaveContract({
        izAllInvoice,
        orderBrandType,
        resultId,
        description: form.description,
        invoiceTitleId: form.id,
        invoiceType: form.invoiceType,
        orderIds: izAllInvoice === '0' ? orderIds : [],
      })
        .then((res) => {
          if (res.code === '0') {
              this.$message.success('操作成功');
              this.$router.replace({ path: '/syoung/invoice/invoice-record',
            });
          }
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
    // 发票类型改变
    changeInvoiceType(val) {
      if (val === 'SPECIAL_VAT_INVOICE' && this.form.titleType !== 'ENTERPRISE') {
        this.form.titleType = 'ENTERPRISE';
        this.changeTitleType();
      }
    },
    // 重置发票抬头
    changeTitleType() {
      this.form.id = '';
    },
    handeChange(val) {
      this.form = { 
        ...this.form, 
        ...this.listForOrder.find(item => {
          delete item.invoiceType;
          return item.id === val;
        }), 
      };
    },
    addTitle() {
      this.$router.replace('/syoung/shop/contract-info');
    },
    backPage() {
      this.$router.replace('/syoung/invoice/invoice-order');
    },
  },
};
</script>
<style lang="scss" scoped>
.invoice-header {
  .content {
    background: #fff;
    .title {
      margin-top: 15px;
      font-size: 14px;
    }
    .form-div {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      .el-form {
        width: 50%;
      }
      .btn-div {
        margin-left: 20px;
      }
    }
    .price {
      p {
        width: 98px;
        text-align: right;
        display: inline-block;
      }
      span {
        color: red;
      }
    }
    .sure-div {
      width: 50%;
      text-align: center;
    }
  }
  .el-cascader {
    width: 100%;
  }
}
</style>